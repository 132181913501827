<template>
  <div class="files-view-container">
    <div v-if="!loading" class="files-view-wrap">
      <div class="files-back-btn" @click="back">
        <i aria-hidden="true" class="nexd-icon-32-creative-updated" />
        <div class="files-back-btn-txt">Back to start</div>
      </div>

      <template v-if="images">
        <div class="files-header-txt">Nice!</div>
        <div class="files-info-wrap">
          <div class="files-info-txt flex-grow">
            First round of optimization resulted you a
            <span class="color-success">{{ optimization }}%</span> decrease in
            asset size.
          </div>
        </div>
        <div class="files-detailed-info row">
          <div class="col flex-grow">
            <div class="row">
              <div class="col-8">
                <div class="files-data-content">
                  {{ original_size | FileSize(2) }}
                </div>
                <div class="files-data-info">
                  Original file{{ images.length > 1 ? 's' : '' }}
                </div>
              </div>
              <div class="col-8">
                <div class="files-data-content">
                  {{ decreased_size | FileSize(2) }}
                </div>
                <div class="files-data-info">Size decreased</div>
              </div>
              <div class="col-8">
                <div class="files-data-content text-blue">
                  {{ optimized_size | FileSize(2) }}
                </div>
                <div class="files-data-info">
                  Optimized file{{ images.length > 1 ? 's' : '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col files-btn-container">
            <div class="btn btn-link btn-share" @click="copyZipLink">
              <i aria-hidden="true" class="nexd-icon-32-share" />
              <span>Copy ZIP link</span>
            </div>
            <div
              v-if="!uploading"
              class="btn btn-link btn-upload-more"
              @click="openFileInput"
            >
              <span>Upload more</span>
            </div>
            <div v-else class="btn btn-link">
              <i
                aria-hidden="true"
                class="nexd-icon-32-creative-updated"
              /><span>Uploading ({{ files_count }})</span>
            </div>
            <a
              :href="zip_link"
              v-position-aware
              download
              target="_blank"
              class="btn btn-primary files-download-btn"
            >
              Download ZIP
            </a>
            <span id="zip-download-size">Zip size: {{ zip_size | FileSize(2) }}</span>
            <input
              ref="input"
              type="file"
              class="file-upload-input"
              id="upload-more"
              :accept="file_input_accept_extensions"
              multiple
              @change="fileChangeHandler"
            />
          </div>
        </div>
        <FilesList :images="images" @updateZip="updateZip" />
      </template>
      <div v-else>Optimizer session not found</div>
    </div>
    <Loading :fill="true" v-else />
  </div>
</template>

<script>
import FilesList from '@root/src/components/FilesList';
import Loading from '@master/UI/Loading';
import { getBase64, setClipboard } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';

export default {
  name: 'FilesView',
  components: {
    FilesList,
    Loading,
  },

  computed: {
    accepted_mimes() {
      if (this.user != null) {
        return [].concat(
          AssetHelper.getImageMimes(),
          AssetHelper.getVideoMimes(),
          ['text/html', 'application/zip', 'application/x-zip-compressed'],
        );
      }
      return AssetHelper.getImageMimes();
    },

    file_input_accept_extensions() {
      return AssetHelper.mimeToExtension(this.accepted_mimes);
    },

    allowed_size() {
      if (this.user != null) {
        return 100;
      }
      return 20;
    },
  },

  data() {
    return {
      user: null,
      loading: true,

      optimization: 0,
      original_size: 0,
      decreased_size: 0,
      optimized_size: 0,
      images: null,
      zip_data: null,
      zip_link: null,
      zip_size: 0,
      uploading: false,
      files_count: 0,
      upload_error_message: null,
    };
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);

    this.getSession();
  },

  methods: {
    back() {
      this.$router.push('/');
    },

    getSession() {
      const path = `tools/optimizer/${this.$route.params.session_id}`;
      this.$http
        .get(path, { notification: false })
        .then(response => {
          this.original_size = response.original_total_size;
          this.optimized_size = response.optimized_total_size;
          this.decreased_size = this.original_size - this.optimized_size;

          this.optimization =
            100 - Math.round((this.optimized_size / this.original_size) * 100);

          this.zip_size = response.zip_size;
          this.zip_link = response.zip_link;
          this.images = response.files;
        })
        .catch(_ => {
          /** suppress sess not found error */
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    updateZip(zip_size, original_total_size, optimized_total_size) {
      this.original_size = original_total_size;
      this.optimized_size = optimized_total_size;

      let optimization = (
        (this.optimized_size / this.original_size) *
        100
      ).toFixed(2);
      this.optimization = (100 - optimization).toFixed(2);

      this.decreased_size = this.original_size - this.optimized_size;

      this.zip_size = zip_size;
    },

    openFileInput() {
      if (this.$refs.input) {
        this.$refs.input.click();
      }
    },

    fileChangeHandler(event) {
      const convertings = [];
      for (const img of event.target.files) {
        convertings.push(
          new Promise((resolve, reject) => {
            const is_supported_type = AssetHelper.isAcceptedMime(
              this.accepted_mimes,
              img.type,
            );
            const is_limit_exceeded = this.isLimitExceeded(img.size);

            if (is_supported_type && !is_limit_exceeded) {
              return getBase64(img).then(b64_string => {
                const obj = {
                  name: img.name,
                  type: img.type,
                  size: img.size,
                  data: b64_string,
                };
                resolve(obj);
              });
            }

            if (!is_supported_type && is_limit_exceeded) {
              reject(
                new Error(
                  `Upload limit of ${this.allowed_size} MB exceeded and this file type is not supported!`,
                ),
              );
            } else if (!is_supported_type) {
              reject(new Error('This file type is not supported!'));
            } else {
              reject(
                new Error(`Upload limit of ${this.allowed_size} MB exceeded!`),
              );
            }
          }),
        );
      }

      Promise.all(convertings)
        .then(files => {
          this.sendFiles(files);
        })
        .catch(error => {
          this.upload_error_message = error;
        });
    },

    sendFiles(files) {
      this.files_count = files.length;
      this.uploading = true;

      const path = `tools/optimizer/${this.$route.params.session_id}`;
      this.$http
        .post(path, { data: files })
        .then(_ => {
          if (this.$refs.input) {
            this.$refs.input.value = '';
          }
          this.getSession();
        })
        .finally(_ => {
          this.uploading = false;
        });
    },

    copyZipLink() {
      if (this.zip_link != null) {
        const url = this.zip_link;
        setClipboard(url)
          .then(_ => {
            this.$notifications.add('primary', 'Zip URL copied to clipboard');
          })
          .catch(error => {
            this.$notifications.add('warning', error);
          });
      }
    },

    isLimitExceeded(size) {
      return Math.round(size / (1024 * 1024)) > this.allowed_size;
    },
  },
};
</script>
