<template>
  <div class="nexd-info-container">
    <div class="nexd-info-wrap">
      <div
        class="nexd-info-first-block"
        :class="{ 'nexd-info-first-block-no-feather': user != null }"
      >
        <div class="nexd-info-header" v-if="user == null">
          Nexd Asset Optimizer
        </div>
        <div class="nexd-info-feather" v-if="user == null">
          Light as feather
        </div>
        <div class="nexd-info-subheader">Why use a media optimizer?</div>
        <div class="row col-24">
          <div class="col-12">
            <div class="nexd-info-label text-right">Lower costs</div>
            <div class="nexd-info-content text-right">
              Improve your bottom line by compressing and optimizing image and
              video files. Smaller files use less bandwidth and save costs.
            </div>
          </div>
          <div class="col-12">
            <div class="nexd-info-label">Happier users</div>
            <div class="nexd-info-content">
              Whether it's in ads, product images, or even background video,
              smaller files mean faster load time, which makes happy users.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NexdInfo',

  data() {
    return {
      user: null,
    };
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);
  },
};
</script>
