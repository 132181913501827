<template lang="pug">
  div.files-list-container
    div.files-list-row.files-list-header
      div
        span File name
        i.nexd-icon-32-arrow-down-small(aria-hidden="true")
      div
        span Orig. size
        i.nexd-icon-32-arrow-down-small(aria-hidden="true")
      div
        span Optimized size
        i.nexd-icon-32-arrow-down-small(aria-hidden="true")
      div
        span Status
        i.nexd-icon-32-arrow-down-small(aria-hidden="true")
      div &nbsp;
    File(v-for="image in images" :key="image.safe_name" :file="image" @update="update")
</template>

<script>
import File from '@root/src/components/File';

export default {
  name: 'FilesList',
  components: {
    File,
  },
  props: {
    images: Array,
  },

  data() {
    return {};
  },

  methods: {
    update(zip_size, original_total_size, optimized_total_size) {
      this.$emit(
        'updateZip',
        zip_size,
        original_total_size,
        optimized_total_size,
      );
    },
  },
};
</script>
