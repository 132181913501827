import { unique, clone, b64toBlob, searchLongStringsAndBreak } from '@helpers/Global';

import { alert, confirm } from '@libs/alerts';

import { TYPE, ASSET, GLOBAL_ASSET, SIZE, DEVICE, MIME_TYPES } from '@master/constants';
import CustomAssetService from '@master/Services/Cache/CustomAssetService';

// cache same asset size calculations
// eg each cube size will have same output
const MEMOIZATION_CACHE = new Map();

const AssetHelper = {
  // helper function copied from FW code, in future should replace with arno
  getResponsiveType(width, height) {
    if (height === 0) return 0;

    const ratio = width / height;
    if (ratio >= 4 || height < 100) {
      return 3;
    } else if (ratio <= 0.7) {
      return 2;
    } else if (ratio >= 1.5) {
      return 1;
    }
    return 0;
  },

  // input can be templatte asset filenames sepperated by comma or url
  isPng(string = null) {
    if (string == null || string.split == null) {
      return false;
    }
    const names = string.split(',');
    for (const n of names) {
      if (/\.png$/.test(n)) {
        // return true on first occurance
        return true;
      }
    }
    return false;
  },

  // input can be templatte asset filenames sepperated by comma or url
  isImage(string = null) {
    if (string == null || string.split == null) {
      return false;
    }

    const names = string.split(',');
    for (const n of names) {
      if (/\.(jpe?g|png|gif|svg|webp)$/.test(n)) {
        // return true on first occurance
        return true;
      }
    }

    // input string can be mime as well
    return /image\//.test(string);
  },

  // input can be templatte asset filenames sepperated by comma or url
  isVideo(string = null) {
    if (string == null || string.split == null) {
      return false;
    }

    const names = string.split(',');
    for (const n of names) {
      if (/\.(mp4|mov)$/.test(n)) {
        // return true on first occurance
        return true;
      }
    }

    // input string can be mime as well
    return /video\//.test(string);
  },

  isMap(filename = null) {
    return filename === 'map_data.json';
  },

  // input can be templatte asset filenames sepperated by comma or url
  isSvg(string = null) {
    if (string == null || string.split == null) {
      return false;
    }

    const names = string.split(',');
    for (const n of names) {
      if (/\.svg$/.test(n)) {
        // return true on first occurance
        return true;
      }
    }

    // input string can be mime as well
    return /image\/svg/.test(string);
  },

  // input can be templatte asset filenames sepperated by comma or url
  isCsv(string = null) {
    if (string == null || string.split == null) {
      return false;
    }

    const names = string.split(',');
    for (const n of names) {
      if (/\.csv$/.test(n)) {
        // return true on first occurance
        return true;
      }
    }

    // input string can be mime as well
    return /text\/csv/.test(string);
  },

  // input can be templatte asset filenames sepperated by comma or url
  isJson(string = null) {
    if (string == null || string.split == null) {
      return false;
    }

    const names = string.split(',');
    for (const n of names) {
      if (/\.json$/.test(n)) {
        // return true on first occurance
        return true;
      }
    }

    // input string can be mime as well
    return /application\/json/.test(string);
  },

  // input asset can be asset obj or template asset obj
  isOverlay(asset = null) {
    if (asset == null) {
      return false;
    }

    // First let's check maybe it's custom gesture or close asset
    if (this.isCustomGesture(asset.asset_id) || this.isCustomClose(asset.asset_id) || this.isCustomArrow(asset.asset_id) || this.isCustomEffect(asset.asset_id)) {
      return false;
    }

    // additionals are overlays
    if (this.isAdditional(asset.asset_id)) {
      return true;
    }

    return asset.position === 'absolute' && asset.type === ASSET.OVERLAY;
  },

  isResponsiveOverlay(asset = null) {
    if (asset == null) {
      return false;
    }

    if (asset.position === 'relative' && asset.type === ASSET.OVERLAY) {
      return true;
    }

    // panorama overlay content
    return asset.asset_id === 'Db6cLSimfzZT';
  },

  isBackground(asset = null) {
    return asset != null && asset.type === ASSET.BACKGROUND;
  },

  isAdditional(asset_id = null) {
    return asset_id != null && asset_id.indexOf('custom_') === 0 && !this.isAdditionalFeed(asset_id);
  },

  isAdditionalFeed(asset_id = null) {
    return asset_id != null && asset_id.indexOf('custom_feed_') === 0;
  },

  isCustom(asset_id = null) {
    return asset_id != null && asset_id.indexOf('custom_') === 0;
  },

  isFloatingObject(asset = null) {
    return asset != null && (asset.asset_id === 'fgWYV3w1EW5v' || asset.asset_id === 'EzVEwpEQ8SCE');
  },

  isCustomGesture(asset_id) {
    return /^custom_gesture/.test(asset_id);
  },

  isCustomClose(asset_id) {
    return /^custom_close/.test(asset_id);
  },

  isCustomArrow(asset_id) {
    return this.isCustomArrowLeft(asset_id) || this.isCustomArrowRight(asset_id);
  },

  isCustomArrowLeft(asset_id) {
    return 'custom_arrow_left' === asset_id;
  },

  isCustomArrowRight(asset_id) {
    return 'custom_arrow_right' === asset_id;
  },

  isCustomEffect(asset_id) {
    return ['custom_effect_custom'].includes(asset_id);
  },

  isCustomBrush(asset_id) {
    return asset_id === 'custom_brush';
  },

  isCustomVideoPlay(asset_id) {
    return /^custom_video_play_/.test(asset_id);
  },

  isCustomVideoPause(asset_id) {
    return /^custom_video_pause_/.test(asset_id);
  },

  isCustomVideoMute(asset_id) {
    return /^custom_video_mute_/.test(asset_id);
  },

  isCustomVideoUnmute(asset_id) {
    return /^custom_video_unmute_/.test(asset_id);
  },

  isCustomVideoControl(asset_id) {
    return this.isCustomVideoPause(asset_id) || this.isCustomVideoPlay(asset_id);
  },

  isCustomAudioControl(asset_id) {
    return this.isCustomVideoMute(asset_id) || this.isCustomVideoUnmute(asset_id);
  },

  isCustomVideoControlAsset(asset_id) {
    return this.isCustomVideoControl(asset_id) || this.isCustomAudioControl(asset_id);
  },

  isCustomLightbox(asset_id) {
    return /^custom_.+_lightbox/.test(asset_id);
  },

  isCustomHotspot(asset_id) {
    return this.isCustomHotspotMain(asset_id) || this.isCustomHotspotPin(asset_id) || this.isCustomHotspotClose(asset_id);
  },

  isCustomHotspotMain(asset_id) {
    return /^custom_hs_.+_main$/.test(asset_id);
  },

  isCustomHotspotPin(asset_id) {
    return /^custom_hs_.+_pin$/.test(asset_id);
  },

  isCustomHotspotClose(asset_id) {
    return /^custom_hs_.+_close$/.test(asset_id);
  },

  isCustomHotspotVideoAsset(asset_id) {
    return /^custom_video_(play|mute)_custom_hs_.+_main$/.test(asset_id);
  },

  isCustomSplash(asset_id) {
    // starts with because of layout modules
    return asset_id.startsWith(GLOBAL_ASSET.SPLASH);
  },

  isHiddenInCropper(asset_id) {
    return (
      this.isCustomSplash(asset_id) ||
      this.isCustomGesture(asset_id) ||
      this.isCustomClose(asset_id) ||
      this.isCustomEffect(asset_id) ||
      this.isCustomVideoPause(asset_id) ||
      this.isCustomVideoUnmute(asset_id) ||
      this.isCustomLightbox(asset_id) ||
      this.isCustomHotspotMain(asset_id) ||
      this.isCustomHotspotPin(asset_id) ||
      this.isCustomHotspotClose(asset_id)
    );
  },
};

AssetHelper.getVideoThumbnail = url => {
  return url.replace(/\.(mp4|mov)$/, '.jpg');
};

AssetHelper.getSizeFromArno = (arno, asset, creative) => {
  const value = arno.api.asset.getInfo(asset.filename);

  if (!value) {
    throw new Error(`Arno error getting values for ${asset.filename}`);
  }

  const total = {
    width: creative.width,
    height: creative.height,
  };

  const dimensions = {
    width: total.width,
    height: total.height,
  };

  // arno does not handle overlays
  if (!AssetHelper.isOverlay(asset)) {
    dimensions.width = AssetHelper.validateDimension(value.w * value.sc.x);
    dimensions.height = AssetHelper.validateDimension(value.h * value.sc.y);
  }

  const recommended = { ...dimensions };

  let recommended_multiplier = 2;
  let scale = 2;

  if (creative.type === TYPE.INTERSTITIAL) {
    recommended_multiplier = SIZE.INTERSTITIAL.WIDTH / dimensions.width;
    scale = 1;
  }

  if (AssetHelper.isOverlay(asset)) {
    recommended.width = dimensions.width * scale;
    recommended.height = dimensions.height * scale;
  } else {
    recommended.width = AssetHelper.validateDimension(recommended.width * recommended_multiplier);
    recommended.height = AssetHelper.validateDimension(recommended.height * recommended_multiplier);
  }

  return {
    dimensions,
    overlay: AssetHelper.isOverlay(asset),
    panorama: false,
    recommended,
    scale,
    total,
    wrapper: {
      width: value.w,
      height: value.h,
    },
    transform: value.transform,
    x: value.x,
    y: value.y,
  };
};

AssetHelper.getlayoutAssetSize = size => {
  // get the asset width and height values
  // < - % of the placement
  // = fixed pixels
  // + up to pixels, but not smaller than placement size (croptool logic after asset is being selected)
  const regexp = new RegExp(/<|=|\+/i);
  if (size.replace != null) {
    return parseInt(size.replace(regexp, ''), 10);
  }
  return size;
};

AssetHelper.getSizeDimensions = (creative, options, layout, asset_object) => {
  // if creative has a setting floating: true (on cube, carousel) the asset is smaller
  // when the setting is false, the asset is within full size
  // thus when the setting exists and its false, we ignore the <84 or whatever size the assets has and use <100 instead
  const ignore_asset_dimensions = creative?.settings?.floating === false;

  const is_mobile = creative.device === DEVICE.MOBILE;
  const is_desktop = creative.device === DEVICE.DESKTOP;
  const is_responsive = creative.device === DEVICE.RESPONSIVE;

  const force_overlay = options.force_overlay != null ? options.force_overlay : false;
  const is_overlay = force_overlay || AssetHelper.isOverlay(asset_object);
  const is_background = AssetHelper.isBackground(asset_object);

  const cache_key = JSON.stringify([creative.device, creative.type, creative.width, creative.height, is_overlay, is_background, asset_object.width, asset_object.height, options, ignore_asset_dimensions]);

  const cached_info = MEMOIZATION_CACHE.get(cache_key);
  if (cached_info) {
    return clone(cached_info);
  }

  // full asset sizes
  let total = {
    width: options.placement_width ?? creative.width,
    height: options.placement_height ?? creative.height,
  };

  // duplicate total to dimensions
  let dimensions = { ...total };

  // interscroller special cases to show cropper correctly
  if (creative.type === TYPE.INTERSCROLLER) {
    if (layout != null && layout.type === TYPE.INTERSCROLLER && is_overlay) {
      // interscroller template, get sizes by using high aspect width
      // for interscroller template overlays, keep them related with placement size
      const ar = total.height / total.width;
      dimensions.width = SIZE.INTERSTITIAL.WIDTH;
      dimensions.height = dimensions.width * ar;

      // interscroller creative with fullscreen template, as well default case
      total.width = SIZE.INTERSTITIAL.WIDTH;
      total.height = SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;
    } else {
      // interscroller creative with fullscreen template, as well default case
      total.width = SIZE.INTERSTITIAL.WIDTH;
      total.height = SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;

      // height can have few pixel offset from 854 (legacy asset before interscroller was changed to high aspect ratio size)
      // using interstitail sizes instead of high aspect ratio sizes
      if (AssetHelper.isFullscreenAsset(asset_object)) {
        total.width = SIZE.INTERSTITIAL.WIDTH;
        total.height = SIZE.INTERSTITIAL.HEIGHT;
      }

      // set dimensions as total
      dimensions = { ...total };
    }
  }

  if (is_overlay || ignore_asset_dimensions) {
    asset_object.width = '<100';
    asset_object.height = '<100';
  } else {
    if (options.force_width != null) {
      asset_object.width = options.force_width;
    } else if (asset_object.width == null) {
      // default if size is missing
      asset_object.width = '<100';
    }
    if (options.force_height != null) {
      asset_object.height = options.force_height;
    } else if (asset_object.height == null) {
      // default if size is missing
      asset_object.height = '<100';
    }
  }

  // legacy CTA bar rule
  if (creative.settings != null && creative.settings.cta != null && creative.settings.cta.enabled === true && dimensions.height > SIZE.CTA) {
    dimensions.height -= SIZE.CTA;
  }

  const wop = asset_object.width[0];
  const hop = asset_object.height[0];

  const is_panorama = wop === '+' || hop === '+';

  const asset_width = AssetHelper.getlayoutAssetSize(asset_object.width);
  const asset_height = AssetHelper.getlayoutAssetSize(asset_object.height);

  // recommended size may b2 x2, as well for certain cases we show rec. size as <100x<100
  // eg for cube that has assts <83x<83, we show rec. as 600x500 for placement 300x250 (instead of 500x416 that would be <83)
  let recommended_multiplier = 1;

  switch (wop) {
    case '<':
      dimensions.width = (dimensions.width * asset_width) / 100;
      if (asset_width > 50 && asset_width < 100) {
        recommended_multiplier = 100 / asset_width;
      }
      break;
    case '+':
      // panorama exception ,return string with leading +
      // will be checked in croptool as well in CM
      dimensions.width = '+' + asset_width;
      break;
    default:
      // = case, as well default fallback
      dimensions.width = parseInt(asset_width, 10);
  }

  switch (hop) {
    case '<':
      dimensions.height = (dimensions.height * asset_height) / 100;
      break;
    case '+':
      // panorama exception ,return string with leading +
      // will be checked in croptool as well in CM
      dimensions.height = '+' + asset_height;
      break;
    default:
      // = case, as well default fallback
      dimensions.height = parseInt(asset_height, 10);
  }

  // copy dimensions as rec. before validation
  let recommended = { ...dimensions };

  // validate that the dimensions are /2 after making them to be rec.
  if (wop === '<') {
    dimensions.width = AssetHelper.validateDimension(dimensions.width);
  }
  if (hop === '<') {
    dimensions.height = AssetHelper.validateDimension(dimensions.height);
  }

  // multiply rec. size to show normalized assed size as recommended
  if (wop !== '+') {
    recommended.width *= recommended_multiplier;
  }
  if (hop !== '+') {
    recommended.height *= recommended_multiplier;
  }

  let scale = 1;
  if (is_desktop || (is_mobile && total.width < 600)) {
    scale = 2;

    // VR showroom exception, which is the only infeed type layout with fixed sizes and which doesnt use arno
    if (hop === '=' && wop === '=' && layout != null && layout.type === 0) {
      scale = 1;
    }

    // scale everything except panoramas
    if (wop !== '+') {
      recommended.width *= scale;
      recommended.width = AssetHelper.validateDimension(recommended.width);
    } else if (dimensions.width === '+2500') {
      // some weird panorama case to ask up to 2500, but sicne its scaled x2, we show up to 1250 for coorect aspect ratio
      dimensions.width = '+1250';
    }

    // scale everything except panoramas
    if (hop !== '+') {
      recommended.height *= scale;
      recommended.height = AssetHelper.validateDimension(recommended.height);
    } else if (dimensions.height === '+2500') {
      // some weird panorama case to ask up to 2500, but sicne its scaled x2, we show up to 1250 for coorect aspect ratio
      dimensions.height = '+1250';
    }
  } else {
    // < sizes should be validated
    if (wop !== '+') {
      recommended.width = AssetHelper.validateDimension(recommended.width);
    }
    if (hop !== '+') {
      recommended.height = AssetHelper.validateDimension(recommended.height);
    }
  }

  // quantum crop sizes are different
  // where asset size is equal to the relation of the global placement 640x640
  // while 100% asset is =640x=640, but quantum qube is =480x=480
  if (is_responsive) {
    if (force_overlay) {
      // if sizeset specific overlay, the area will be ad size / total  size
      // global overalys are as relative =640x=150 as 1:1 spec
      dimensions.width = total.width;
      dimensions.height = total.height;
      // sizeset specific overlays are scaled x2
      scale = 2;
    } else if (is_background) {
      // background size changed by the asset Slot component
      // output should be correct without any changes
    } else {
      // other responsive assets will be in relation of asset width vs creative width (640x640 for responsive cube and carousel assets which are =480 and =600)
      if (is_panorama) {
        // skip this for panorama
      } else {
        const asset_ratio = creative.width / asset_width;
        recommended.width *= asset_ratio;
        recommended.height *= asset_ratio;
      }
    }

    // hide placement layer from cropper by assiging ad total size the same as croparea / dimensions
    total.width = dimensions.width;
    total.height = dimensions.height;
  }

  total = AssetHelper.validateDimensions(total);

  const output = {
    dimensions,
    recommended,
    total,
    scale,
    overlay: is_overlay,
    panorama: is_panorama,
    wrapper: null,
  };
  MEMOIZATION_CACHE.set(cache_key, output);
  return clone(output);
};

AssetHelper.getSize = (creative, asset_id, options = {}) => {
  const template = creative.template;
  let asset_object = {};
  if (asset_id != null && (creative?.additional_assets[asset_id] != null || template?.assets[asset_id] != null)) {
    asset_object = clone(creative.additional_assets[asset_id] || template.assets[asset_id]);
  }
  return AssetHelper.getSizeDimensions(creative, options, template, asset_object);
};

// Helper function to tell if the uploaded real asset is fullscreen asset by sizes (cropping may add few pixels to prevent framework sizing errors)
AssetHelper.isFullscreenAsset = asset => {
  const width = {
    min: SIZE.FW.INTERSTITIAL.WIDTH - 2,
    max: SIZE.FW.INTERSTITIAL.WIDTH + 2,
  };
  const height = {
    min: SIZE.FW.INTERSTITIAL.HEIGHT - 2,
    max: SIZE.FW.INTERSTITIAL.HEIGHT + 2,
  };
  const har_height = {
    min: SIZE.FW.INTERSTITIAL.HEIGHT_HIGH_ASPECT - 2,
    max: SIZE.FW.INTERSTITIAL.HEIGHT_HIGH_ASPECT + 2,
  };
  return asset.width >= width.min && asset.width <= width.max && ((asset.height >= height.min && asset.height <= height.max) || (asset.height >= har_height.min && asset.height <= har_height.max));
};

AssetHelper.validateDimensions = dimensions => {
  let { width, height } = dimensions;
  width = AssetHelper.validateDimension(width);
  height = AssetHelper.validateDimension(height);
  return { width, height };
};

AssetHelper.validateDimension = dimension => {
  if (typeof dimension === 'number') {
    // fix floating point
    dimension = Math.round(dimension * 100) / 100;
    // round up
    dimension = Math.ceil(dimension);
    // make sure the output is dividable by 2
    if (!isNaN(dimension) && dimension % 2 !== 0) {
      dimension++;
    }
  }
  return dimension;
};

AssetHelper.getImageMimes = () => {
  return Object.keys(MIME_TYPES?.IMAGE ?? {});
};

AssetHelper.getVideoMimes = () => {
  const excluded_from_ui = ['video/x-m4v', 'video/x-matroska', 'video/x-msvideo', 'video/webm', 'video/ogg'];

  return Object.keys(MIME_TYPES?.VIDEO ?? {}).filter(mime => {
    return !excluded_from_ui.includes(mime);
  });
};

AssetHelper.getCSVMimes = () => {
  return Object.keys(MIME_TYPES?.CSV ?? {});
};

AssetHelper.getHTMLMimes = () => {
  return Object.keys(MIME_TYPES?.HTML ?? {});
};

AssetHelper.getZIPMimes = () => {
  return Object.keys(MIME_TYPES?.ZIP ?? {});
};

AssetHelper.isCSVMime = (mime, filename = null) => {
  // use filename.extension as a falback
  // problematic case: if windows machine has no inforamtion about a file type in the registry (for chrome), the type/mime will be empty string
  if (mime === '' && filename != null) {
    return filename.split('.').pop() === MIME_TYPES?.CSV?.['text/csv'];
  }
  return AssetHelper.getCSVMimes().includes(mime);
};

AssetHelper.getXLSMimes = () => {
  return Object.keys(MIME_TYPES?.XLS ?? {});
};

AssetHelper.isXLSMime = (mime, filename = null) => {
  // use filename.extension as a falback
  // problematic case: if windows machine has no inforamtion about a file type in the registry (for chrome), the type/mime will be empty string
  if (mime === '' && filename != null) {
    return Object.values(MIME_TYPES?.XLS ?? {}).includes(filename.split('.').pop());
  }
  return AssetHelper.getXLSMimes().includes(mime);
};

/**
 * @param {*} filename template filenames, mostly csv type of list.jpg,list.svg etc
 * @param {*} exclude_video  if gif and video will be excluded
 * @param {*} exact when true, only matching extension mime will be returned (instead of returning all images mimes for .jpg extension)
 */
AssetHelper.getAcceptedMimes = (filename = null, exclude_video = false, exact = false) => {
  // mostly additional overlay assets have no filename until uploaded, let em have mime of image
  if (filename == null) {
    filename = 'fallback.jpeg';
  }

  const filenames = filename.split(',');

  let accepted_mimes_image = [];
  let accepted_mimes_video = [];
  let accepted_mimes_other = [];

  for (const name of filenames) {
    // get extension from filename
    const type = name.split('.').pop();

    switch (type) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'svg': {
        let image_mimes = AssetHelper.getImageMimes();
        if (exact) {
          image_mimes = image_mimes.filter(mime => mime.includes(type));
        }
        if (exclude_video) {
          image_mimes = image_mimes.filter(mime => mime !== 'image/gif');
        }
        accepted_mimes_image.push(...image_mimes);
        break;
      }
      case 'mp4': {
        if (!exclude_video) {
          accepted_mimes_video.push(...AssetHelper.getVideoMimes());
          if (!exact) {
            accepted_mimes_image.push('image/gif');
          }
        }
        break;
      }
      case 'json':
      case 'csv':
      case 'xls':
      case 'xlsx':
        if (!exact || type === 'csv') {
          accepted_mimes_other.push(...AssetHelper.getCSVMimes());
        }
        if (!exact || type !== 'xls' || type !== 'xlsx') {
          accepted_mimes_other.push(...AssetHelper.getXLSMimes());
        }
        break;
      default:
        accepted_mimes_image.push(...AssetHelper.getImageMimes());
        break;
    }
  }

  // filter out duplicates to output
  let accepted_mimes = {
    image: unique(accepted_mimes_image),
    video: unique(accepted_mimes_video),
    other: unique(accepted_mimes_other),
  };

  // join to strings
  accepted_mimes.image = accepted_mimes.image.join(', ');
  accepted_mimes.video = accepted_mimes.video.join(', ');
  accepted_mimes.other = accepted_mimes.other.join(', ');
  return accepted_mimes;
};

AssetHelper.getMimeExtension = mime => {
  mime = mime.trim();
  for (const TYPE in MIME_TYPES) {
    for (const MIME in MIME_TYPES[TYPE]) {
      if (mime === MIME.trim()) {
        return MIME_TYPES[TYPE][MIME];
      }
    }
  }
  return null;
};

AssetHelper.mimeToExtension = mimes => {
  let array_of_mimes = mimes;

  if (typeof mimes === 'string') {
    array_of_mimes = mimes.split(',');
  }

  let extensions = new Set();
  for (const mime of array_of_mimes) {
    const extension = AssetHelper.getMimeExtension(mime);
    if (extension) {
      extensions.add(`.${extension}`);
      if (extension === 'jpg') {
        // add also acceptable jpeg
        extensions.add('.jpeg');
      }
    }
  }
  return Array.from(extensions).join(', ');
};

AssetHelper.isAcceptedMime = (mimes, file_type, filename = null) => {
  // folders have non file_type
  if (file_type === '') {
    if (filename != null) {
      // for windows fallback, since windows doesnt recognize csv mime and will output mime as empty string
      if (/\.csv$/.test(filename)) {
        file_type = 'text/csv';
      } else if (/\.mkv$/.test(filename)) {
        // Seems like browser doesnt recognise mkv mime neither
        file_type = 'video/x-matroska';
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  if (file_type === 'image/jpeg') {
    file_type = 'image/jpg';
  } else if (file_type === 'image/svg+xml') {
    file_type = 'image/svg';
  }
  return mimes.indexOf(file_type) !== -1;
};

AssetHelper.getExtensionsFromFilename = (filename, type, is_google_ads = false) => {
  const is_video_endcard = filename?.includes('endcard');
  const is_splash = type === ASSET.SPLASH;

  const mimes = AssetHelper.getAcceptedMimes(filename, is_video_endcard || is_google_ads, is_splash);

  if (AssetHelper.isMap(filename)) {
    return {
      mimes: mimes.other,
      extensions: AssetHelper.mimeToExtension(mimes.other),
    };
  }

  const accepted_mimes = [mimes.image, mimes.video].filter(Boolean).join(', ');

  const accepted_extensions = [AssetHelper.mimeToExtension(mimes.image), AssetHelper.mimeToExtension(mimes.video)].filter(Boolean).join(', ');

  return {
    mimes: accepted_mimes,
    extensions: accepted_extensions,
  };
};

AssetHelper.getOverlayPosition = (creative, asset_id, size, overlays_inside_placement = false) => {
  // if something calls it with delay, return if the creative obj is already removed (reported by bugsnag)
  if (creative == null) {
    return;
  }
  const asset = creative.assets[asset_id] || creative.additional_assets[asset_id];

  // legacy fix before cropper normalize
  if (asset.output_scale == null) {
    if (creative.device === 0) {
      const ratio = SIZE.FW.INTERSTITIAL.WIDTH / creative.width;
      asset.output_scale = ratio;
    } else {
      asset.output_scale = 1;
    }
  }

  let dimensions = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  if (asset == null) {
    return dimensions;
  }

  // fallback defaults
  let options = {
    offset_x: 0,
    offset_y: 0,
    anchor_x: 'center',
    anchor_y: 'center',
  };

  let scale = asset.output_scale ?? asset.user_scale ?? 2;

  // take offset and anchors from sourcelayer settings
  if (asset.settings?.SourceLayer != null) {
    const SourceLayer = asset.settings.SourceLayer;
    const CropLayer = asset.settings.CropLayer;

    if (SourceLayer.offset != null) {
      // cap offsets from -1 to 1, since overlay will be cropped when over the edge
      options.offset_x = Math.min(1, Math.max(-1, SourceLayer.offset.x));
      options.offset_y = Math.min(1, Math.max(-1, SourceLayer.offset.y));
    }

    if (SourceLayer.anchor != null) {
      options.anchor_x = SourceLayer.anchor.x;
      options.anchor_y = SourceLayer.anchor.y;
    }

    // when asset overflows both sides of the slot
    // consider scale as the "user_scale" which is @x2 by default
    // output scale cannot be used because the asset is being cut off from both ends and therefore has a huge diff between size selected and output generated
    const overflows_x = SourceLayer.size.width - Math.abs(SourceLayer.position.x) > CropLayer.size.width;
    const overflows_y = SourceLayer.size.height - Math.abs(SourceLayer.position.y) > CropLayer.size.height;
    if (overflows_x || overflows_y) {
      scale = asset.user_scale ?? scale;
    }
  } else if (creative.settings?.assets?.[asset_id] != null) {
    // if sourcelyaer settings is missing, check legacy settings object
    for (const key in options) {
      if (creative.settings.assets[asset_id][key] != null) {
        options[key] = creative.settings.assets[asset_id][key];
      }
    }
  }

  // overlay svg-s are 1:1 original, take cropper settings for visible width and height
  if (AssetHelper.isSvg(asset.uri) && asset.settings != null) {
    if (asset.settings != null && asset.settings.SourceLayer != null && asset.settings.SourceLayer.size != null) {
      dimensions.width = asset.settings.SourceLayer.size.width;
      dimensions.height = asset.settings.SourceLayer.size.height;
    } else {
      dimensions.width = asset.width || 0;
      dimensions.height = asset.height || 0;
    }
  } else {
    dimensions.width = asset.width / scale;
    dimensions.height = asset.height / scale;
  }

  // half sizes to fix offset centers
  const cw = size.width * 0.5;
  const ch = size.height * 0.5;
  const aw = dimensions.width * 0.5;
  const ah = dimensions.height * 0.5;

  if (options.anchor_x === 'right') {
    // right
    dimensions.x = options.offset_x * cw - aw;
  } else if (options.anchor_x === 'left') {
    // left
    dimensions.x = options.offset_x * cw + aw;
  } else {
    // center
    dimensions.x = options.offset_x * cw;
  }

  let position_offset_fix = 0;

  // only for infeed scroll, overlay position will be inside normalized placement box
  if (overlays_inside_placement) {
    const ratio = SIZE.INTERSTITIAL.WIDTH / creative.width;
    const real_placement_height = creative.height * ratio;
    position_offset_fix = (size.height - real_placement_height) / 2;
  }

  if (options.anchor_y === 'bottom') {
    // bottom
    dimensions.y = options.offset_y * ch - ah - position_offset_fix;
  } else if (options.anchor_y === 'top') {
    // top
    dimensions.y = options.offset_y * ch + ah + position_offset_fix;
  } else {
    // center
    dimensions.y = options.offset_y * ch;
  }

  return dimensions;
};

AssetHelper.isSpecialLayout = template_id => {
  const layouts = ['PRZQ'];
  return layouts.includes(template_id);
};

/**
 * basically some asset use different asset values from arno
 * Also arno accepts filename without the extension, so we remove anything external as well
 */
AssetHelper.getTemplateAssetForArno = (template_asset = null) => {
  // when no assets its probably crop for the extra asset like
  // arrorws or gesture, thus we take the settings/area from the 1st carousel image
  if (!template_asset || !template_asset.filename) {
    return { filename: '1' };
  }

  let filename = template_asset.filename.split('.').shift();
  if ((!isNaN(parseInt(filename)) && parseInt(filename) > 3) || filename.startsWith('feed_')) {
    filename = '1';
  } else if (filename === 'endcard') {
    filename = 'top';
  } else if (template_asset.asset_id && template_asset.asset_id.startsWith('custom_')) {
    filename = 'cta';
  }
  return { ...template_asset, filename };
};

AssetHelper.createCropperBackgroundLayers = creative => {
  let layers = {
    Background: null,
    Assets: null,
    Overlay: null,
  };

  let canvas_width = creative.width;
  let canvas_height = creative.height;

  // fullscreen mobile placement
  if (creative.device === DEVICE.MOBILE) {
    // canvas_width = SIZE.FW.INTERSTITIAL.WIDTH;
    if (creative.type === TYPE.INFEED || creative.type === TYPE.INTERSTITIAL) {
      // canvas_height = canvas_width * ar;
    } else if (creative.type === TYPE.INTERSCROLLER) {
      // interscrollers placement size does not match the real ad / template size, which is high aspect ratio interstitial
      canvas_width = SIZE.INTERSTITIAL.WIDTH;
      canvas_height = SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;
    }
  }

  for (const name in layers) {
    const canvas = document.createElement('canvas');
    canvas.width = canvas_width;
    canvas.height = canvas_height;
    const ctx = canvas.getContext('2d');
    ctx.translate(canvas.width * 0.5, canvas.height * 0.5);

    // default image layer z indexes (but below CropLayer which is 600)
    let zIndex = 550;
    if (name === 'Background') {
      // default backgrounds lower than images
      zIndex = 400;
    } else if (name === 'Overlay') {
      // default overlays obove everything else
      zIndex = 1200;
    }

    layers[name] = {
      name,
      canvas,
      ctx,
      zIndex,
      has_images: false,
      img: null,
    };
  }

  return { layers, canvas_width, canvas_height };
};

AssetHelper.drawCropperBackgroundLayers = (layers, drawings, callback, creative) => {
  for (const obj of drawings) {
    const { img, x, y, width, height, layer_type } = obj;
    let ctx;
    if (layer_type === 'bg') {
      ctx = layers.Background.ctx;
      layers.Background.has_images = true;
    } else if (layer_type === 'overlay') {
      ctx = layers.Overlay.ctx;
      layers.Overlay.has_images = true;
    } else {
      ctx = layers.Assets.ctx;
      layers.Assets.has_images = true;
    }

    if (img == null) {
      ctx.fillStyle = creative.settings.asset_background_color || '#BECBDC';
      ctx.fillRect(x - width * 0.5, y - height * 0.5, width, height);
    } else {
      ctx.drawImage(img, x - width * 0.5, y - height * 0.5, width, height);
    }
  }

  for (const name in layers) {
    if (layers[name].has_images) {
      layers[name].img = window.URL.createObjectURL(b64toBlob(layers[name].canvas.toDataURL('image/png')));
      // for testing enable those lines
      // let img = document.createElement('img');
      // img.alt = name;
      // img.src = layers[name].img;
      // document.body.appendChild(img);
    }
  }
  callback(layers);
};

function getDrawableAssets(creative, slot_vnode) {
  const template_assets = creative.template?.assets;
  if (!template_assets) return [];

  const assets = creative.assets;

  // take out assets cropper ignores
  const valid_template_assets = Object.values(template_assets).filter(asset => {
    return assets[asset.asset_id] !== null && !AssetHelper.isJson(asset.filename) && !AssetHelper.isCsv(asset.filename) && !AssetHelper.isHiddenInCropper(asset.asset_id) && !slot_vnode.singleLayerAsset(asset.asset_id);
  });

  const overlays = [];
  const backgrounds = [];
  const other = [];

  for (const template_asset of valid_template_assets) {
    if (AssetHelper.isOverlay(template_asset)) {
      overlays.push(template_asset.asset_id);
    } else if (AssetHelper.isBackground(template_asset)) {
      backgrounds.push(template_asset.asset_id);
    } else {
      other.push(template_asset.asset_id);
    }
  }

  const additional = getDrawableAdditionalAssets(creative.additional_assets);
  return [...backgrounds, ...other, ...overlays.concat(additional).reverse()];
}

function getDrawableAdditionalAssets(assets) {
  const asset_ids = Object.keys(assets).filter(asset_id => {
    // custom assets/additionals are hidden from cropper
    return !AssetHelper.isHiddenInCropper(asset_id);
  });

  return asset_ids;
}

function getHotspotAssetIds(assets, active_asset_id) {
  return Object.keys(assets).filter(asset_id => AssetHelper.isCustomHotspotMain(asset_id) && active_asset_id.includes(asset_id.match(/custom_hs_(.*?)_/gi)[0]));
}

AssetHelper.addCropperBackgroundLayers = (cropper, layers, asset) => {
  if (cropper == null) return;

  for (const layer of Object.values(layers)) {
    if (!layer.img) {
      continue;
    }

    let zIndex = layer.zIndex;

    // by default the cropp/soure layers are in the middle
    // but when cropping a background asset, make sure everything is above
    if (AssetHelper.isBackground(asset)) {
      zIndex += 1000;
    }

    const image = cropper.addBackgroundImage(layer.img, {
      zIndex: zIndex,
      name: layer.name,
    });
    if (image != null) {
      image.getElement().style.filter = 'brightness(0.7)';
    }
  }
};

/**
 * Helper function that will create background images used by cropper
 * Will create 3 different layers of images
 * Background - the most bottom layer for cropper, can be transparent if empty, dark overlay will cover the images
 * Overlay    - the most top layer for cropper,    can be transparent if emtpy, dark overlay will not cover the images
 * Assets     - the common assets layer, will be between backround and overlay, will be gray asset color of no assets found, dark overlay will cover the image
 */
AssetHelper.createCropperBackground = async (slot_vnode, callback, active_asset_id, asset_object = null) => {
  const creative = slot_vnode.creative;
  const active_is_custom_video_control = AssetHelper.isCustomVideoControlAsset(active_asset_id);
  const active_is_overlay = AssetHelper.isOverlay(asset_object) || active_is_custom_video_control;
  const active_is_background = AssetHelper.isBackground(asset_object);

  if (slot_vnode.can_use_arno) {
    // will also handle null input
    asset_object = AssetHelper.getTemplateAssetForArno(asset_object);

    const sizes = AssetHelper.getSizeFromArno(slot_vnode.arno, asset_object, creative);
    asset_object.x = sizes.x;
    asset_object.y = sizes.y;
  }

  const { layers, canvas_width, canvas_height } = AssetHelper.createCropperBackgroundLayers(creative);

  let asset_ids = getDrawableAssets(creative, slot_vnode);

  // when doing hotspot close or hotspot video assets, also add hotspot asset to the stack
  if (AssetHelper.isCustomHotspotClose(active_asset_id) || AssetHelper.isCustomHotspotVideoAsset(active_asset_id)) {
    asset_ids = asset_ids.concat(getHotspotAssetIds(creative.additional_assets, active_asset_id));
  }

  let drawings = [];
  const loaded = () => {
    AssetHelper.drawCropperBackgroundLayers(layers, drawings, callback, creative);
  };

  // track where non overlay assets are drawn, since we only draw the 1st overlay asset if theres multiple to the same location
  let asset_layers = [];

  // push first active layer coorinates to the layers list, so we wont draw anything to the same coorinates
  // except if its overlay, then we want to have the first required asset
  if (asset_object != null && !active_is_background && !active_is_overlay) {
    asset_layers.push({
      tx: asset_object.x || 0,
      ty: asset_object.y || 0,
    });
  }

  let assets_to_draw = [];

  if (AssetHelper.isCustomArrowLeft(active_asset_id)) {
    const exists = asset_ids.some(AssetHelper.isCustomArrowRight);
    if (!exists) {
      const first = CustomAssetService.get('custom_arrow_right').first();
      if (first) {
        assets_to_draw.push({ blob: first.blob, x: creative.width * 0.5 - 20, y: 0, width: 40, height: 40, layer_type: 'overlay' });
      }
    }
  } else if (AssetHelper.isCustomArrowRight(active_asset_id)) {
    const exists = asset_ids.some(AssetHelper.isCustomArrowLeft);
    if (!exists) {
      const first = CustomAssetService.get('custom_arrow_left').first();
      if (first) {
        assets_to_draw.push({ blob: first.blob, x: -creative.width * 0.5 + 20, y: 0, width: 40, height: 40, layer_type: 'overlay' });
      }
    }
  }

  for (const asset_id of asset_ids) {
    if (asset_id === active_asset_id) continue;

    let template_asset = creative.template?.assets?.[asset_id] ?? null;
    const additional_asset = creative.additional_assets?.[asset_id] ?? null;

    // nothing to draw
    if (!template_asset && !additional_asset) continue;

    let asset = creative.assets[asset_id] ?? creative.additional_assets[asset_id] ?? null;

    // dont draw uploaded assets that hasve not been cropped yet
    if (asset != null && asset.output_scale == null) {
      continue;
    }

    // identifier key for which background layer group it belongs
    let layer_type = 'assets';

    // mock template asset from additional asset
    if (template_asset == null && additional_asset != null) {
      template_asset = {
        filename: additional_asset.filename,
        required: 0,
        type: 2,
      };
    }

    let arno_sizes = null;

    // if arno, add template asset positions from arno
    if (slot_vnode.can_use_arno && !AssetHelper.isAdditional(asset_id)) {
      // Can't get size for additional overlays
      arno_sizes = AssetHelper.getSizeFromArno(slot_vnode.arno, AssetHelper.getTemplateAssetForArno(template_asset), creative);
      template_asset.x = arno_sizes.x;
      template_asset.y = arno_sizes.y;
    }

    // non uploaded assets are replaced with gray rects
    if (asset == null) {
      if (template_asset.required !== 1 || AssetHelper.isFloatingObject(template_asset)) {
        // dont create fake gray assets on non required assets
        continue;
      }

      let sizes = arno_sizes;

      // if we have no arno, get sizes by the asset description to mock the asset
      if (!arno_sizes) {
        sizes = AssetHelper.getSize(creative, asset_id);
      }

      asset = {
        asset_id,
        image_uri: null,
        width: sizes.dimensions.width,
        height: sizes.dimensions.height,
        user_scale: 1,
        output_scale: 1,
      };
    }

    let image_uri = asset.image_uri;

    let width = asset.width;
    let height = asset.height;

    let x = 0;
    let y = 0;

    // this block is disabled until FW catches up with SVG support
    // currently backend now hacks the svg to be full asset size, so logic how to position it in thumbnail is not needed
    // if (AssetHelper.isSvg(uri) && asset.settings != null) {
    //   width = asset.settings.SourceLayer.size.width;
    //   height = asset.settings.SourceLayer.size.height;
    //   x = asset.settings.SourceLayer.position.x;
    //   y = asset.settings.SourceLayer.position.y;
    // }
    if (AssetHelper.isAdditional(asset_id) || AssetHelper.isOverlay(template_asset)) {
      if (image_uri == null || image_uri === '') {
        // dont draw overlay assets with no image_uri
        continue;
      }

      const size = { width: canvas_width, height: canvas_height };

      // video controls are placed on top of the slot
      // MVP solution is to find the 1st main slot and use that size to find its position
      if (AssetHelper.isCustomVideoControlAsset(asset_id)) {
        if (creative.template?.assets != null) {
          for (const asset_id in creative.template?.assets) {
            const candidate = creative.template.assets[asset_id];
            if (AssetHelper.isVideo(candidate.filename) && candidate.required === 1) {
              const candidate_size = AssetHelper.getSizeDimensions(creative, {}, null, candidate);
              // use main asset size to place video controls
              size.width = candidate_size.dimensions.width;
              size.height = candidate_size.dimensions.height;
              break;
            }
          }
        }
      }

      const dimensions = AssetHelper.getOverlayPosition(creative, asset_id, size, slot_vnode.overlays_inside_placement);
      x = dimensions.x;
      y = dimensions.y;
      width = dimensions.width;
      height = dimensions.height;
      layer_type = 'overlay';

      // when we crop overlay, lets check the order of the drawable assets
      if (active_is_overlay) {
        const target_index = asset_ids.indexOf(asset_id);
        let active_index = asset_ids.indexOf(active_asset_id);

        if (active_is_custom_video_control) {
          // video setting slot holds parent id
          active_index = asset_ids.indexOf(slot_vnode.asset_id);
        }

        // assets that belong below the active assets are drawn layer blow
        // same index can only happen if its a child/control asset for overlay
        if (active_index >= target_index) {
          layer_type = 'assets';
        }
      }
      assets_to_draw.push({ image_uri, x, y, width, height, layer_type });
    } else {
      // legacy fix before cropper normalize
      if (asset.output_scale == null) {
        if (creative.device === 0) {
          const ratio = SIZE.FW.INTERSTITIAL.WIDTH / creative.width;
          asset.output_scale = ratio;
        } else if (creative.device === 1) {
          asset.output_scale = 2;
        } else {
          asset.output_scale = 1;
        }
      }

      width /= asset.output_scale;
      height /= asset.output_scale;

      let tx = template_asset.x || 0;
      let ty = template_asset.y || 0;
      x = tx;
      y = ty;

      // skip this part for background assets
      if (!AssetHelper.isBackground(template_asset)) {
        // dont include same position assets
        let includes = false;
        for (const layer of asset_layers) {
          if (layer.tx === tx && layer.ty === ty) {
            includes = true;
            break;
          }
        }

        // if same position is already in drawing order, dont add it to the draw list
        if (includes) continue;

        // special case for asset x and y, if they are -1 or 1, they are positioned on top or bottom (like overlays)
        if (x === 1) {
          x = canvas_width / 2 - width / 2;
        } else if (x === -1) {
          x = -canvas_width / 2 + width / 2;
        }
        if (y === 1) {
          y = canvas_height / 2 - height / 2;
        } else if (y === -1) {
          y = -canvas_height / 2 + height / 2;
        }

        // save drawing x and y as well template x and y position to prevent drawing same location assets twice
        asset_layers.push({ x, y, tx, ty });
      } else {
        layer_type = 'bg';
      }

      if (image_uri != null) {
        // add or overwrite each slot asset to be drawn (image x video)
        assets_to_draw.push({ image_uri, x, y, width, height, layer_type });
      }
    }
  }

  // if no drawings are made, fire loaded instantly (case with one asset, the same which is active)
  if (assets_to_draw.length === 0) {
    loaded();
    return;
  }

  for (const asset of assets_to_draw) {
    const index = drawings.push(null) - 1;
    AssetHelper.loadAssetForCropperBackground(asset).then(loaded_asset => {
      drawings[index] = loaded_asset;
      if (!drawings.includes(null)) {
        loaded();
      }
    });
  }
};

AssetHelper.loadAssetForCropperBackground = asset => {
  return new Promise(resolve => {
    if (asset.image_uri == null && asset.blob == null) {
      // draws gray box
      asset.img = null;
      return resolve(asset);
    }

    asset.img = new Image();
    asset.img.crossOrigin = 'Anonymous';

    let src = null;
    if (asset.image_uri) {
      const hash = '' + Math.round(Math.random() * 1000000000);
      src = asset.image_uri.split('?')[0] + '?' + hash;
    } else if (asset.blob) {
      src = asset.blob;
    }

    asset.img.src = src;

    asset.img.onload = () => {
      resolve(asset);
    };

    // fallback on image error, to skip the file
    asset.img.onerror = () => {
      asset.img = null;
      resolve(asset);
    };
  });
};

AssetHelper.getImageDimensions = file => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = window.URL.createObjectURL(file);
  });
};

AssetHelper.isValidFile = async file => {
  let width = 0;
  let height = 0;
  if (AssetHelper.isVideo(file.type)) {
    const metadata = await AssetHelper.getVideoMetadata(file);
    width = metadata.width;
    height = metadata.height;
    if (metadata.duration < 1) {
      await alert(
        undefined,
        'You uploaded an video that is too short (under 1 second). This could create flickering for the ad viewer and we cannot allow that.' + ' Please upload a longer video (or use a still image instead).',
        'Uploaded video is too short',
      );
      return false;
    }
  } else if (AssetHelper.isImage(file.type)) {
    const metadata = await AssetHelper.getImageDimensions(file);
    width = metadata.width;
    height = metadata.height;
  } else {
    // any non image or video file does not / cannot have validation
    return true;
  }

  // if user presses okay we mark it as "valid" file, so it woult not be skipped on uploads
  if (width > SIZE.MAX_FILE_DIMENSION || height > SIZE.MAX_FILE_DIMENSION) {
    return await confirm(undefined, `File '${searchLongStringsAndBreak(file.name)}' exceeds our asset dimension limits (max. ${SIZE.MAX_FILE_DIMENSION}px).`, 'We will automatically resize the file');
  }

  return true;
};

// helper fn to get the metadata from video file
AssetHelper.getVideoMetadata = file => {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      const width = video.videoWidth;
      const height = video.videoHeight;
      resolve({ duration, width, height });
    };
    video.onerror = () => {
      resolve({ duration: 0, width: 0, height: 0 });
    };
    video.src = window.URL.createObjectURL(file);
  });
};

// some video formats are not supported for chrome
// chrome will output width and height 0 if the video is not supported
AssetHelper.isVideoSupported = file => {
  return new Promise(resolve => {
    AssetHelper.getVideoMetadata(file).then(metadata => {
      resolve(metadata.width > 0 && metadata.height > 0);
    });
  });
};

AssetHelper.getVideoDuration = file => {
  return new Promise(resolve => {
    AssetHelper.getVideoMetadata(file).then(metadata => {
      resolve(metadata.duration);
    });
  });
};

AssetHelper.getFrameFromVideo = src => {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.src = src;
    video.crossOrigin = 'anonymous';
    video.onloadeddata = () => {
      video.currentTime = 0;
    };
    video.ontimeupdate = () => {
      let canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      let ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL('image/jpeg'));
      window.URL.revokeObjectURL(video.src);
    };
  });
};

AssetHelper.getVideoExportSize = (creative, type = null) => {
  // if no type given, try getting type from creative object
  if (type == null) {
    type = 'x1'; // fallback
    if (creative.settings && creative.settings.video_export && creative.settings.video_export.type) {
      type = creative.settings.video_export.type;
    }
  }

  // fallback x1 sizes = creative sizes
  let width = creative.width;
  let height = creative.height;

  if (type === 'x2') {
    width *= 2;
    height *= 2;
  } else if (type === 'fb-1080') {
    width = 1080;
    height = 1080;
  } else if (type === 'hq') {
    if (width > height) {
      width = 1920;
      height *= 1920 / creative.width;
    } else {
      width *= 1920 / creative.height;
      height = 1920;
    }
  } else if (type === 'landscape-16by9') {
    width = 1920;
    height = 1080;
    // height = Math.floor(width / (16 / 9));
  } else if (type === 'portrait-9by16') {
    width = 1080;
    height = 1920;
    // width = Math.floor(height / (16 / 9));
  }

  return AssetHelper.validateDimensions({ width, height });
};

AssetHelper.getVideoExportMinimumSize = creative => {
  if (creative == null) return {};

  let type = 'x1'; // fallback
  if (creative.settings && creative.settings.video_export && creative.settings.video_export.type) {
    type = creative.settings.video_export.type;
  }

  // helper calculaton functions
  function getWidth(_height) {
    return (_height * creative.width) / creative.height;
  }
  function getHeight(_width) {
    return (_width * creative.height) / creative.width;
  }

  // default creative sizes, since rec. is x2 anyway
  let width = creative.width;
  let height = creative.height;

  if (type === 'fb-1080') {
    if (width <= height) {
      height = 1080;
      width = getWidth(height);
    } else {
      width = 1080;
      height = getHeight(width);
    }
  } else if (type === 'landscape-16by9') {
    if (width <= height) {
      height = 1080;
      width = getWidth(height);
    } else {
      width = 1920;
      height = getHeight(width);
    }
  } else if (type === 'portrait-9by16') {
    if (width <= height) {
      height = 1920;
      width = getWidth(height);
    } else {
      width = 1080;
      height = getHeight(width);
    }
  } else if (type === 'hq') {
    if (width <= height) {
      height = 1920;
      width = getWidth(height);
    } else {
      width = 1920;
      height = getHeight(width);
    }
  }

  return AssetHelper.validateDimensions({ width, height });
};

export default AssetHelper;
