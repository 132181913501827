<template lang="pug">
  div#app-wrapper
    FilesView
    AdFooter
</template>

<script>
import FilesView from '@root/src/components/FilesView';
import AdFooter from '@root/src/components/AdFooter';

export default {
  name: 'Landing',
  components: {
    FilesView,
    AdFooter,
  },
};
</script>
