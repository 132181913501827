<template>
  <div class="adfooter-container">
    <div class="adfooter-wrap">
      <div class="adfooter-header-small text-center">
        Want to build ads as light and fast as the assets you just optimized?
        Try out
      </div>
      <div class="adfooter-header-big text-center">Campaign Manager</div>
      <div class="adfooter-img-container">
        <div class="left-image">
          <img
            :src="$cdn + 'dist/assets/optimizer/laptop.svg'"
            style="height: 260px"
            alt="laptop"
          />
        </div>
        <div class="front-image">
          <img :src="$cdn + 'dist/assets/optimizer/laptop.svg'" alt="laptop" />
        </div>
        <div class="right-image">
          <img
            :src="$cdn + 'dist/assets/optimizer/laptop.svg'"
            style="height: 260px"
            alt="laptop"
          />
        </div>
      </div>
      <div class="adfooter-text text-center">
        NEXD Campaign Manager eliminates complexity from the ad production
        process, without sacrificing any of the creativity today's audience
        demands.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdFooter',
};
</script>
