<template>
  <div class="nexd-info-container">
    <div class="nexd-info-wrap">
      <div class="nexd-info-second-block">
        <div class="nexd-info-header header-bigger">The NEXD advantage</div>
        <div class="row col-24">
          <div class="col-6">
            <div class="nexd-info-label-number nexd-info-label-rocket"></div>
            <div class="nexd-info-label label-bigger text-center">
              Super-fast
            </div>
            <div class="nexd-info-content text-center">
              Our blazing-fast compression will optimize your image and video
              files in an instant
            </div>
          </div>
          <div class="col-6">
            <div class="nexd-info-label-number nexd-info-label-hand"></div>
            <div class="nexd-info-label label-bigger text-center">
              Easy-to-use
            </div>
            <div class="nexd-info-content text-center">
              Enjoy our simple hassle-free, drag & drop interface. It just takes
              the click of a button and it’s done
            </div>
          </div>
          <div class="col-6">
            <div class="nexd-info-label-number nexd-info-label-stack"></div>
            <div class="nexd-info-label label-bigger text-center">
              Robust infrastructure
            </div>
            <div class="nexd-info-content text-center">
              Asset Optimizer runs on enterprise-grade infrastructure, designed
              to handle millions of requests per minute
            </div>
          </div>
          <div class="col-6">
            <div class="nexd-info-label-number nexd-info-label-box"></div>
            <div class="nexd-info-label label-bigger text-center">
              It's free!
            </div>
            <div class="nexd-info-content text-center">
              Get access to best-in-class media optimization technology, without
              paying a penny
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NexdAdvantages',
};
</script>
