<template>
  <div class="file-container" :class="{ active: !collapsed }">
    <div class="files-list-row single-file">
      <div class="overflow-ellipsis">{{ file.original_filename }}</div>
      <div>{{ options.original.size | FileSize(2) }}</div>
      <div>{{ options.optimized.size | FileSize(2) }}</div>
      <div class="file-container-status">
        <img
          :src="$cdn + 'dist/assets/optimizer/optimization-success.svg'"
          alt="Success"
        />
      </div>
      <div class="file-details-btn file-details-btn-collapsed">
        <div v-if="!collapsed" class="flex flex-align-center">
          <div class="btn btn-link" @click="copyDownloadLink">
            <i aria-hidden="true" class="nexd-icon-32-share" />
          </div>
          <div class="file-collapse" @click="toggleCollapse">
            <i aria-hidden="true" class="nexd-icon-32-arrow-up-small" />
          </div>
        </div>
        <div v-else class="text-nowrap" @click="toggleCollapse">
          <span>Detailed view</span>
          <span class="file-collapse file-collapse-rotated">
            <i aria-hidden="true" class="nexd-icon-32-arrow-up-small" />
          </span>
        </div>
      </div>
    </div>

    <AssetOptimizer
      v-if="!collapsed"
      :original="options.original"
      :optimized="options.optimized"
      :session_id="file.session_id"
      @change="optimizeChangeHandler"
    />

    <div ref="video_frame_drawer" style="display: none"></div>
  </div>
</template>

<script>
import AssetOptimizer from '@master/UI/AssetOptimizer';

import { setClipboard } from '@helpers/Global';

export default {
  name: 'File',
  components: {
    AssetOptimizer,
  },
  props: {
    file: Object,
  },
  data() {
    return {
      collapsed: true,

      // basically UIM optimizer options, will be reactive as well
      options: {
        session_id: this.file.session_id,
        original: {
          size: this.file.original_filesize,
          uri: this.file.original_uri,
          url: this.file.original_url,
        },
        optimized: {
          size: this.file.optimized_filesize,
          uri: this.file.optimized_uri,
          url: this.file.optimized_url,
          quality: this.file.quality,
        },
      },
    };
  },

  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },

    optimizeChangeHandler(result) {
      const { zip_size, original_total_size, optimized_total_size } = result;
      this.$emit('update', zip_size, original_total_size, optimized_total_size);
    },

    copyDownloadLink() {
      const url = this.file.optimized_url;
      setClipboard(url)
        .then(_ => {
          this.$notifications.add('primary', 'File URL copied to clipboard');
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },
  },
};
</script>
