<template>
  <div class="file-upload-container" ref="file-upload-container">
    <div class="file-upload-content">
      <h1 class="file-upload-header">Optimize your media here</h1>
      <div class="file-upload-info">
        Whether it's in ads, product images or even background video, smaller
        files use less bandwidth and save money! Also faster load time keeps
        your customers happy.
      </div>
      <div class="file-upload-btn-wrap">
        <div class="btn btn-primary" v-position-aware @click="openFileInput">
          Choose files
        </div>
        <div class="file-upload-btn-info">Or drag and drop them here</div>
        <input
          ref="input"
          type="file"
          class="file-upload-input"
          id="file-upload"
          :accept="file_input_accept_extensions"
          multiple
          @change="fileChangeHandler"
        />
      </div>
      <div v-if="upload_error_message != null" class="file-upload-error-wrap">
        <div class="error-header">Whoops!</div>
        <div class="error-info">{{ upload_error_message }}</div>
      </div>
    </div>
    <div class="file-info-container">
      <div class="row">
        <div class="col-12">
          <div class="file-info-content text-right">Allowed file types:</div>
          <div class="file-info-content bold text-right">
            {{ allowed_types }}
          </div>
        </div>
        <div class="col-12">
          <div class="file-info-content">Maximum asset size:</div>
          <div class="file-info-content bold">{{ allowed_size }} MB</div>
        </div>
      </div>
      <div class="file-info-scroll-container" v-if="user != null">
        <img
          :src="$cdn + 'dist/assets/optimizer/scroll-icon.svg'"
          alt="scroll-icon"
        />
      </div>
    </div>
    <div class="file-arrow top-left"></div>
    <div class="file-arrow bottom-left"></div>
    <div class="file-arrow top-right"></div>
    <div class="file-arrow bottom-right"></div>
    <div v-if="dropbox_visible || uploading" class="file-upload-dropbox">
      <div class="file-upload-overlay"></div>
      <div v-if="!uploading" class="file-dropbox-content">
        <div class="dropbox-btn-wrapper">
          <div class="dropbox-btn">
            <img
              :src="$cdn + 'dist/assets/optimizer/file-upload-btn.svg'"
              alt="Drop assets here"
            />
          </div>
          <div class="dropbox-header">Drop your assets here</div>
        </div>
        <div class="dropbox-info-container">
          <div class="col-24 row">
            <div class="col-14">
              <div class="dropbox-info-content">Allowed file types:</div>
              <div class="dropbox-info-content bold">{{ allowed_types }}</div>
            </div>
            <div class="col-10">
              <div class="dropbox-info-content">Maximum asset size:</div>
              <div class="dropbox-info-content bold">{{ allowed_size }} MB</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="file-dropbox-content file-dropbox-content-uploading">
        <div class="dropbox-loader-container">
          <div class="dropbox-loader"></div>
          <div class="dropbox-loader-counter">{{ files_count }}</div>
        </div>
        <div class="dropbox-header">Uploading</div>
      </div>
      <div class="file-arrow top-left"></div>
      <div class="file-arrow bottom-left"></div>
      <div class="file-arrow top-right"></div>
      <div class="file-arrow bottom-right"></div>
    </div>
  </div>
</template>

<script>
import { getBase64 } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';

export default {
  name: 'FileUpload',

  data() {
    return {
      user: null,
      dropbox_visible: false,
      uploading: false,
      files_count: 0,
      upload_error_message: null,
    };
  },

  computed: {
    accepted_mimes() {
      if (this.user != null) {
        return [].concat(
          AssetHelper.getImageMimes(),
          AssetHelper.getVideoMimes(),
          AssetHelper.getHTMLMimes(),
          AssetHelper.getZIPMimes(),
        );
      }
      return AssetHelper.getImageMimes();
    },

    file_input_accept_extensions() {
      return AssetHelper.mimeToExtension(this.accepted_mimes);
    },

    allowed_types() {
      return this.file_input_accept_extensions
        .split(', ')
        .map(ext => {
          if (ext === '.jpeg') return;
          return ext.replace('.', '').toUpperCase();
        })
        .join(' ');
    },

    allowed_size() {
      return this.user ? 100 : 20;
    },
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);
  },

  mounted() {
    document.body.addEventListener('dragover', this.dragOverHandler, false);
    document.body.addEventListener('drop', this.dropHandler, false);
    document.body.addEventListener('dragleave', this.dragLeaveHandler, false);
  },

  beforeDestroy() {
    document.body.removeEventListener('dragover', this.dragOverHandler, false);
    document.body.removeEventListener('drop', this.dropHandler, false);
    document.body.removeEventListener(
      'dragleave',
      this.dragLeaveHandler,
      false,
    );
  },

  methods: {
    dragOverHandler(e) {
      e.preventDefault();
      this.dropbox_visible = true;
    },

    dropHandler(e) {
      e.preventDefault();
      this.fileChangeHandler({ target: { files: e.dataTransfer.files } });
    },

    dragLeaveHandler(e) {
      e.preventDefault();
      this.dropbox_visible = false;
    },

    openFileInput() {
      if (this.$refs.input) {
        this.$refs.input.click();
      }
    },

    fileChangeHandler(event) {
      const convertings = [];

      for (const img of event.target.files) {
        convertings.push(
          new Promise((resolve, reject) => {
            const is_supported_type = AssetHelper.isAcceptedMime(
              this.accepted_mimes,
              img.type,
            );
            const is_limit_exceeded = this.isLimitExceeded(img.size);

            if (is_supported_type && !is_limit_exceeded) {
              return getBase64(img).then(b64_string => {
                const obj = {
                  name: img.name,
                  type: img.type,
                  size: img.size,
                  data: b64_string,
                };
                resolve(obj);
              });
            }

            if (!is_supported_type && is_limit_exceeded) {
              reject(
                new Error(
                  `Upload limit of ${this.allowed_size} MB exceeded and this file type is not supported!`,
                ),
              );
            } else if (!is_supported_type) {
              reject(new Error('This file type is not supported!'));
            } else {
              reject(
                new Error(`Upload limit of ${this.allowed_size} MB exceeded!`),
              );
            }
          }),
        );
      }

      Promise.all(convertings)
        .then(files => {
          this.sendFiles(files);
        })
        .catch(error => {
          this.upload_error_message = error;
          this.dropbox_visible = false;
        });
    },

    sendFiles(files) {
      this.files_count = files.length;
      if (this.files_count === 0) {
        throw new Error('No files dropped');
      }

      this.uploading = true;
      const path = 'tools/optimizer';
      this.$http
        .post(path, files, { notification: false })
        .then(response => {
          this.$router.push({
            name: 'optimizer',
            params: { session_id: response.id },
          });
        })
        .catch(_ => {
          this.upload_error_message =
            'Failed to initialize optimizer session. Please try again.';
        })
        .finally(_ => {
          if (this.$refs.input) {
            this.$refs.input.value = '';
          }
          this.uploading = false;
          this.dropbox_visible = false;
        });
    },

    isLimitExceeded(size) {
      return size / 1048576 > this.allowed_size;
    },
  },
};
</script>
