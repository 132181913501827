<template lang="pug">
div#app-wrapper
  FileUpload
  NexdInfo
  NexdAdvantages
  AdFooter
</template>

<script>
import NexdInfo from '@root/src/components/NexdInfo';
import FileUpload from '@root/src/components/FileUpload';
import AdFooter from '@root/src/components/AdFooter';
import NexdAdvantages from '@root/src/components/NexdAdvantages';

export default {
  name: 'Landing',
  components: {
    NexdInfo,
    FileUpload,
    AdFooter,
    NexdAdvantages,
  },
  data() {
    return {
      user: null,
    };
  },
  created() {
    this.$user.subscribe(user => {
      this.user = user;
    }, this);
  },
};
</script>
